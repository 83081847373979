<template>
  <div>
    <b-modal
      v-model="showModal"
      hide-header
      hide-footer
      centered
      size="xl"
      no-close-on-backdrop
    >
      <div class="w-100 p-1">
        <div class="search d-flex justify-content-between">
          <b-input-group class="panel-input-serach w-100">
            <b-form-input
              class="input-serach"
              placeholder="ชื่อสินค้า, SKU"
              v-model="filter.Search"
              @keyup="handleSearch"
            ></b-form-input>
            <b-input-group-prepend @click="btnSearch">
              <span class="icon-input m-auto pr-2">
                <font-awesome-icon icon="search" title="View" />
              </span>
            </b-input-group-prepend>
          </b-input-group>
        </div>
        <b-row class="no-gutters table-detail mt-3">
          <b-col>
            <b-table
              responsive
              class="text-center table-list"
              striped
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(ids)="data">
                <b-form-checkbox
                  size="lg"
                  class="ml-3"
                  :value="data.item.id"
                  v-model="selected"
                  @change="val => handleItem(data.item, val)"
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(imageUrl)="data">
                <div class="position-relative">
                  <div
                    class="square-box b-contain"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.imageUrl + ')',
                    }"
                  ></div>
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <p class="mb-0 nobreak two-lines">{{ data.item.name }}</p>
              </template>
              <template v-slot:cell(sku)="data">
                <p class="mb-0 nobreak two-lines">{{ data.item.sku }}</p>
              </template>
              <template v-slot:cell(price)="data">
                <p class="mb-0 nobreak two-lines">{{ data.item.price }}</p>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-sm-flex m-3">
            <b-pagination
              v-model="filter.PageNo"
              :total-rows="rows"
              :per-page="filter.PerPage"
              class="m-md-0"
              @change="paginationProduct"
              align="center"
            ></b-pagination>
          </div>
          <div class="d-sm-flex m-3">
            <b-button class="button btn-cancel ml-2" @click="hide()"
              >ย้อนกลับ</b-button
            >
            <b-button
              :disabled="selected.length == 0"
              class="btn-main ml-3"
              @click="handleData"
              >เพิ่มสินค้า</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      type: String,
    },
    productSelectIds: {
      required: false,
      type: Array,
    },
  },
  data() {
    return {
      fields: [
        { key: "ids", label: "เลือก", class: "w-50px text-nowrap" },
        { key: "imageUrl", label: "ภาพประกอบ", class: "w-100px text-nowrap" },
        {
          key: "name",
          label: "ชื่อสินค้า",
          class: "w-100px text-nowrap",
        },
        { key: "sku", label: "SKU", class: "w-100px text-nowrap" },
        { key: "price", label: "ราคาขาย", class: "w-100px text-nowrap" },
      ],
      filter: {
        PageNo: 1,
        PerPage: 5,
        Search: "",
        productSelectIds: [],
      },
      showModal: false,
      isBusy: false,
      items: [],
      selected: [],
      selectedData: [],
      rows: 0,
      index: "",
      timer: null,
    };
  },
  created: async function () {
    await this.getList();
  },
  methods: {
    async show() {
      this.selected = [];
      this.selectedData = [];
      await this.paginationProduct(1)
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    handleData() {
      this.$emit("handleData", this.selectedData);
      this.hide();
    },
    getList: async function () {
      this.isBusy = true;
      let filter = {...this.filter}
      filter.productSelectIds = this.productSelectIds
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Tag/ProductList`,
        null,
        this.$headers,
        filter
      );

      if (resData.result == 1) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;
      }
      this.isBusy = false;
    },
    async handleItem(item, val) {
      let selected = [...this.selected]

      if(val) {
        selected.push(item.id);
        this.selectedData.push(item);
      }else{
        this.selectedData = await this.selectedData.filter((el) => el.id != item.id);
      }
    },
    paginationProduct(Page) {
      this.filter.PageNo = Page;
      this.getList();
    },
    handleSearch(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filter.PageNo = 1;
        this.getList();
      }, 800);
    },
    btnSearch() {
      this.filter.PageNo = 1;
      this.getList();
    },
  },
};
</script>

<style scoped>
::v-deep .modal-body .table-detail {
  min-height: 40rem;
}

.modal-header {
  border: 0 !important;
  padding: 0;
}

.text-text {
  color: #16274a;
  margin-bottom: 0;
  font-size: 26px;
}

.img {
  width: 100px;
}

.search {
  border: 1px solid #bcbcbc;
}

.modal-body {
  margin: auto;
  text-align: center;
}

::v-deep .panel-input-serach {
  max-width: 100% !important;
  border: none !important;
}

@media (max-width: 820px) {
  ::v-deep .modal-dialog {
    max-width: 700px !important;
  }
}
</style>
